/* CustomButton.css */

.custom-button {
  font-size: 1em;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.custom-button.primary {
  background-color: #4caf50;
  color: #ffffff;
}

.custom-button.secondary {
  background-color: #e53935;
  color: #ffffff;
}

.custom-button:hover {
  background-color: #333;
  color: #fff;
}
